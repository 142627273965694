import useAxios from '../../useAxios';
import NavTop from '../../layout/NavTop';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { chatTargetInfoAtom } from '../../atom';
import { useNavigate } from 'react-router-dom';
// infinite scroll
import { useInView } from 'react-intersection-observer';
// 날짜 포맷
import { format, formatDistanceToNow } from 'date-fns';
import { ko } from 'date-fns/locale';

export default function ChattingList() {
	const pAxios = useAxios();
	const navigate = useNavigate();
	const [chatList, setChatList] = useState(null);
	const [page, setPage] = useState(1); // 현재 페이지 번호 (페이지네이션)
	const [ref, inView] = useInView();
	const [chatTargetInfo, setChatTargetInfo] = useRecoilState(chatTargetInfoAtom);

	const fetchChatList = async () => {
		try {
			await pAxios.get(`/chat/list?page_no=${page}&length=8`).then((result) => {
				setChatList(result.data);
			});
		} catch (err) {
			console.log(err);
		}
	};

	const formatDate = (date) => {
		const d = new Date(date);
		const now = Date.now();
		const diff = (now - d.getTime()) / 1000; // 현재 시간과의 차이(초)
		if (diff < 60 * 1) {
			// 1분 미만일땐 방금 전 표기
			return '방금 전';
		}
		if (diff < 60 * 60 * 24 * 3) {
			// 3일 미만일땐 시간차이 출력(몇시간 전, 몇일 전)
			return formatDistanceToNow(d, { addSuffix: true, locale: ko });
		}
		return format(d, 'P', { locale: ko }); // 날짜 포맷
	};

	const moveToChat = (roomId, targetInfo) => {
		setChatTargetInfo((prev) => ({ ...prev, roomId, targetInfo, chatRoot: 'ChatList' }));
		navigate(`/chatting-room/${targetInfo.id}`);
	};

	useEffect(() => {
		fetchChatList();
	}, []);

	return (
		<section>
			<NavTop title={'채팅'} />
			<div className="h-full flex flex-col">
				<ul role="list" className="divide-y divide-gray-100" style={{ height: '100%', overflowY: 'scroll' }}>
					{chatList !== null ? (
						chatList.map((row) => (
							<li
								key={row.id}
								className="flex justify-between gap-x-6 mb-5 mt-3 bg-neutral p-3 rounded-md"
								onClick={() => moveToChat(row.id, row.talker)}
							>
								<div className="flex min-w-0 gap-x-4">
									{row.talker.gender === 'F' ? (
										<div className="h-12 w-12 flex rounded-full justify-center items-center mr-2">
											<img src="/images/images/profile-men.svg" alt="" />
										</div>
									) : (
										<div className="h-12 w-12 flex rounded-full justify-center items-center mr-2">
											<img src="/images/images/profile-women.svg" alt="" />
										</div>
									)}
									<div className="min-w-0 flex-auto">
										<p className="text-sm font-semibold leading-6">
											{row.talker.username}
											<span className="text-xs"> {row.talker.gender === 'F' ? '(여)' : '(남)'}</span>
										</p>
										<p className="mt-1 truncate text-xs leading-5">🥇 {row.talker.gym.name}</p>
									</div>
								</div>
								<div className="shrink-0 sm:flex sm:flex-col sm:items-end justify-center">
									<p className="text-xs leading-6">{row.updated_at ? `${formatDate(row.updated_at)}` : 'null'}</p>
									<div className="mt-1 flex items-center justify-center gap-x-1.5">
										<p className="text-xs leading-5">
											{row.unread_count === 0 ? '' : <div class="badge badge-error badge-md text-xs text-white">{row.unread_count}</div>}
										</p>
									</div>
								</div>
							</li>
						))
					) : (
						<ul>
							<li className="flex justify-between gap-x-6 mb-5 mt-3 bg-neutral p-3 rounded-md animate-pulse">
								<div className="flex min-w-0 gap-x-4">
									<div className="h-12 w-12 flex rounded-full justify-center items-center bg-slate-800"></div>
								</div>
							</li>
						</ul>
					)}
					<li ref={ref} className="py-2"></li>
				</ul>
			</div>
		</section>
	);
}
