import { useRef, useEffect, useState } from 'react';
import useAxios from '../../useAxios';
import { useRecoilState, useRecoilValue } from 'recoil';
import { chatTargetInfoAtom, userInfoAtom } from '../../atom';
// 날짜 포맷
import { format, formatDistanceToNow } from 'date-fns';
import { ko } from 'date-fns/locale';

export default function Chatting({ chatRenderTrigger, chatContent }) {
	const pAxios = useAxios();
	const chatRoomRef = useRef(null);
	const [chatTargetInfo, setChatTargetInfo] = useRecoilState(chatTargetInfoAtom);
	const userInfo = useRecoilValue(userInfoAtom);
	const userId = userInfo.user.id;

	console.log(chatRenderTrigger);
	console.log(chatContent);

	const formatDate = (date) => {
		const d = new Date(date);
		const now = Date.now();
		const diff = (now - d.getTime()) / 1000; // 현재 시간과의 차이(초)
		if (diff < 60 * 1) {
			// 1분 미만일땐 방금 전 표기
			return '방금 전';
		}
		if (diff < 60 * 60 * 24 * 3) {
			// 3일 미만일땐 시간차이 출력(몇시간 전, 몇일 전)
			return formatDistanceToNow(d, { addSuffix: true, locale: ko });
		}
		return format(d, 'P', { locale: ko }); // 날짜 포맷
	};

	return (
		<div ref={chatRoomRef} style={{ overflowX: 'hidden', overflowY: 'scroll', maxHeight: '72vh', height: '100%' }}>
			{chatContent === null ? (
				<div className="flex justify-center items-center h-4/5">loading</div>
			) : chatContent.length === 0 ? (
				'채팅이 없어요 😅 새로운 채팅을 시작 해 주세요 🤩'
			) : (
				<>
					{chatContent.map((data) => (
						<div key={data.id} className={userId === data.sender ? 'flex chat chat-end justify-end mr-4' : 'flex chat chat-start'}>
							{console.log(data)}
							<span className="text-xs">{formatDate(data.created_at)}</span>
							<div className="chat-bubble">{data.content}</div>
						</div>
					))}
				</>
			)}
		</div>
	);
}
