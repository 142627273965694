import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const userRegisterInfoAtom = atom({
	key: 'userRegisterInfo',
	default: {
		username: '',
		age: '',
		gender: '',
		years: null,
		email: '',
		weight: null,
		gym: {
			name: '',
			address: '',
			latitude: null,
			longitude: null,
			sport: null
		}
	}
});

export const userInfoAtom = atom({
	key: 'userInfo',
	default: {
		access: '',
		user: {
			id: null,
			username: '',
			age: '',
			gender: '',
			years: null,
			last_login: '',
			weight: {
				id: null,
				name: '',
				min_weight: null
			},
			gym: {
				id: null,
				name: '',
				address: '',
				latitude: null,
				longitude: null,
				sport: null
			},
			current_location: ''
		}
	}
});

export const chatTargetInfoAtom = atom({
	key: 'chatTargetInfo',
	default: {
		targetId: null, // 짐리스트 전용
		roomId: null, // 채팅리스트 전용
		targetInfo: null, // 둘 경로에서 접근 했을 떄 같이 업데이트
		chatRoot: null // target root 어딘지 string - ChatList 인지 JymListDetail 인지 chatList 면 roomId 로 요청하고 JymList 면 targetId 로 요청한다.
	},
	//chat target 정보 localstorage 저장 채팅룸에서 새로고침하면 타겟 정보가 날라가기 때문에...주의할점 sending messeage target 을 targetInfo에서 접근해야한다
	effects_UNSTABLE: [persistAtom]
});

// 짐디테일 -> 유저 목록 -> 채팅하기 버튼 누를때 + 추가 채팅목록 -> 타겟 누를 때
// export const chatTargetInfoAtom = atom({
// 	key: 'chatTargetInfo',
// 	default: {
// 		receiverId: null,
// 		receiverName: ''
// 	},
// 	effects_UNSTABLE: [persistAtom]
// });

//authentication
// export const userAtom = atom({
// 	key: 'userAtom',
// 	default: null
// });

// //logedinProvise 위한  recoil
// export const userIdLogedInAtom = atom({
// 	key: 'userIdLogedIn',
// 	default: null
// });
