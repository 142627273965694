import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import App from './App';

// 서버 올릴 때 앞주소 맟추기
axios.defaults.baseURL = 'https://minheeyoo88.pythonanywhere.com/api';
// axios.defaults.baseURL = '/api/'; //local-전용 -> 이제 로컬에서도 서버 주소로 해야..서버에서 보낸 쿠키가 같은 도메인이기 때문에 쿠키가 저장된다..?
axios.defaults.withCredentials = true;
axios.defaults.headers = {
	'Content-Type': 'application/json'
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
